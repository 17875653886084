export const scrollToTop = () => {
  document.body.scrollTop = 0; // For Safari
  document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
};

export const onKeyDownHandler = (event, callback) => {
  if (event.key === 'Enter' || event.key === ' ') {
    callback();
  }
};

export const screenHeight = () => {
  const w = window;
  const d = document;
  const e = d.documentElement;
  const g = d.getElementsByTagName('body')[0];
  // x = w.innerWidth || e.clientWidth || g.clientWidth,
  const y = w.innerHeight || e.clientHeight || g.clientHeight;
  return y;
};

export const scrollWheelListener = () => {
  // Fixes issue with anchor link remaining in the URL and blocking repeat clicks
  if (window.location.hash !== '') {
    window.history.replaceState(null, '', '/');
  }
};
