// extracted by mini-css-extract-plugin
export var contactDetails = "EmployeeRoll-module--contact-details--ohSOT";
export var description = "EmployeeRoll-module--description--i-twh";
export var employee = "EmployeeRoll-module--employee--of-Ri";
export var employeeItem = "EmployeeRoll-module--employee-item--kjeir";
export var image = "EmployeeRoll-module--image---3FJc";
export var imageContainer = "EmployeeRoll-module--image-container--RRxs3";
export var line = "EmployeeRoll-module--line--ctjMt";
export var officeOverlay = "EmployeeRoll-module--office-overlay--Wk+EP";
export var officeOverlayContainer = "EmployeeRoll-module--office-overlay-container--51VeC";
export var position = "EmployeeRoll-module--position--PVhTe";
export var text = "EmployeeRoll-module--text---Z6rY";
export var title = "EmployeeRoll-module--title--1vn+p";