import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Parallax, ParallaxBanner } from 'react-scroll-parallax';

import Content, { HTMLContent } from '../components/Content';
import EmployeeRoll from '../components/EmployeeRoll';
import Layout from '../components/Layout';
import Title from '../components/Title';
import bigThree from '../img/logo_mark_blue.svg';
import { screenHeight } from '../utils';
import * as styles from './AboutPage.module.scss';

export const AboutPageTemplate = ({
  image,
  title,
  aboveFold1,
  aboveFold2,
  heading,
  subHeading,
  description,
  contentComponent,
  helmet,
}) => {
  const PageContent = contentComponent || Content;
  const contentRef = useRef(null);
  const [popdownHeight, setPopdownHeight] = useState(null);

  useEffect(() => {
    const onScroll = () => {
      if (!popdownHeight) {
        setPopdownHeight(contentRef.current.offsetTop - 800 + screenHeight());
      }
    };
    window.addEventListener('scroll', onScroll);

    return () => window.removeEventListener('scroll', onScroll);
  });

  return (
    <Layout popdownHeight={popdownHeight}>
      {helmet || ''}
      <div>
        <div id="index-scroll-view" className="parallax-scroll-content">
          <div className={styles.aboveTheFold}>
            <ParallaxBanner
              className="background-three-parallax"
              layers={[
                {
                  image: bigThree,
                  amount: 0.5,
                },
              ]}
            />
            <div className={styles.titleSection}>
              <Title
                accentBar
                className={styles.title}
                element="h2"
                text={title}
                size="1"
                animated
              />
              <div className={styles.aboveFoldDescription}>
                <p>{aboveFold1}</p>
                <p>{aboveFold2}</p>
              </div>
            </div>
          </div>
          <GatsbyImage
            image={image.childImageSharp.gatsbyImageData}
            alt="Christchurch"
            className={styles.image}
            style={{ minHeight: 500 }}
          />
          <div className="main-content-section" ref={contentRef}>
            <Parallax
              y={[-20, -70]}
              className="parallax-will-change parallax-welcome"
            >
              <div className="welcome-section columns is-tablet">
                <div className="column is-two-fifths">
                  <div className={styles.subHeading}>{subHeading}</div>
                  <Title
                    className={styles.heading}
                    accentBar
                    text={heading}
                    size="1"
                    animated
                  />
                </div>
                <div className="column is-three-fifths">
                  <PageContent
                    className={styles.description}
                    content={description}
                  />
                </div>
              </div>
            </Parallax>

            <section className="section--gradient">
              <div className="columns">
                <div
                  className="column is-12"
                  style={{ paddingBottom: 0, width: '100%' }}
                >
                  <div className="column is-12 lab3-container">
                    <Title accentBar text="Meet The Team" size="1" animated />
                    <EmployeeRoll />
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </Layout>
  );
};

AboutPageTemplate.propTypes = {
  image: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  aboveFold1: PropTypes.string.isRequired,
  aboveFold2: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
  subHeading: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  contentComponent: PropTypes.func.isRequired,
  helmet: PropTypes.object,
};

AboutPageTemplate.defaultProps = {
  helmet: null,
};

const AboutPage = ({ data }) => {
  const { markdownRemark: post } = data;
  return (
    <AboutPageTemplate
      contentComponent={HTMLContent}
      image={post.frontmatter.image}
      title={post.frontmatter.title}
      aboveFold1={post.frontmatter.above_fold}
      aboveFold2={post.frontmatter.above_fold_2}
      heading={post.frontmatter.heading}
      subHeading={post.frontmatter.subheading}
      description={post.html}
      helmet={
        <Helmet titleTemplate="%s | Lab3 Apps">
          <title>{`${post ? post.frontmatter.heading : ''}`}</title>
          <meta
            name="description"
            content={`${post ? post.frontmatter.above_fold_2 : ''}`}
          />
          <meta property="og:title" content={post.frontmatter.heading} />
          <meta property="og:type" content="website" />
          <meta property="og:url" content="/" />
          <meta
            property="og:image"
            content={`https://lab3apps.com${post.frontmatter.image.childImageSharp.gatsbyImageData.src}`}
          />
          <meta
            property="og:description"
            content={post.frontmatter.above_fold_2}
          />
        </Helmet>
      }
    />
  );
};

AboutPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default AboutPage;

export const aboutPageQuery = graphql`
  query AboutPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "about-page" } }) {
      html
      frontmatter {
        title
        above_fold
        above_fold_2
        heading
        subheading
        image {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
          }
        }
      }
    }
  }
`;
