import { StaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import PropTypes from 'prop-types';
import React from 'react';

import * as styles from './EmployeeRoll.module.scss';

class EmployeeRoll extends React.PureComponent {
  render() {
    const { data } = this.props;
    const { edges: employees } = data.allMarkdownRemark;

    return (
      <div
        className="columns is-multiline"
        style={{ marginTop: 20, marginBottom: 100 }}
      >
        {employees &&
          employees.map(({ node: employee }) => (
            <div
              className={`is-parent column is-12-mobile is-6 ${styles.employeeItem}`}
              key={employee.id}
            >
              <article className={styles.employee}>
                <div className={styles.imageContainer}>
                  <div className={styles.officeOverlayContainer}>
                    <div className={styles.officeOverlay}>
                      {employee.frontmatter.office}
                    </div>
                  </div>

                  {employee.frontmatter.image != null ? (
                    <GatsbyImage
                      image={
                        employee.frontmatter.image.childImageSharp
                          .gatsbyImageData
                      }
                      alt="Employee Image"
                      className={styles.image}
                    />
                  ) : (
                    <div className={styles.image} />
                  )}
                </div>

                <div className={styles.text}>
                  <div>
                    <div className={styles.title} to={employee.fields.slug}>
                      {employee.frontmatter.name}
                    </div>
                    <div className={styles.line} />
                    <div className={styles.position} to={employee.fields.slug}>
                      {employee.frontmatter.position}
                    </div>
                    {/* {employee.frontmatter.email ? (
                      <a
                        className={styles.contactDetails}
                        href={`mailto:${employee.frontmatter.email}`}
                      >
                        {employee.frontmatter.email}
                      </a>
                    ) : (
                      ''
                    )}
                    {employee.frontmatter.phone ? (
                      <a
                        className={styles.contactDetails}
                        href={`tel:${employee.frontmatter.phone}`}
                      >
                        {employee.frontmatter.phone}
                      </a>
                    ) : (
                      ''
                    )}
                    {employee.frontmatter.tertiary ? (
                      <p className={styles.contactDetails}>
                        {employee.frontmatter.tertiary}
                      </p>
                    ) : (
                      ''
                    )} */}
                    {/* <div className={styles.description}>{employee.frontmatter.description}</div> */}
                  </div>
                </div>
              </article>
            </div>
          ))}
      </div>
    );
  }
}

EmployeeRoll.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }).isRequired,
};

export default () => (
  <StaticQuery
    query={graphql`
      query EmployeeRollQuery {
        allMarkdownRemark(
          sort: { order: ASC, fields: [frontmatter___name] }
          filter: { fields: { collection: { eq: "employees" } } }
        ) {
          edges {
            node {
              excerpt(pruneLength: 400)
              id
              fields {
                slug
                collection
              }
              frontmatter {
                name
                description
                office
                position
                tertiary
                email
                phone
                image {
                  childImageSharp {
                    gatsbyImageData(
                      width: 360
                      layout: CONSTRAINED
                      placeholder: BLURRED
                    )
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={(data, count) => <EmployeeRoll data={data} count={count} />}
  />
);
